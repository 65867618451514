<template>
  <svg 
    width="30px" 
    height="30px" 
    viewBox="0 0 30 30" 
    version="1.1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g 
      stroke="none" 
      stroke-width="1" 
      fill="none" 
      fill-rule="evenodd"
    >
      <g fill="#fff">
        <g transform="translate(0.000000, 7.000000)">
          <polygon 
            transform="translate(6.818182, 6.666667) scale(-1, 1) rotate(90.000000) translate(-6.818182, -6.666667) " 
            points="3.26420455 5.83333333 8.02840909 1.175 6.81818182 0 0 6.66666667 6.81818182 13.3333333 8.01988636 12.1583333 3.26420455 7.5 13.6363636 7.5 13.6363636 5.83333333" 
          />
          <polygon 
            transform="translate(23.181818, 9.333333) scale(-1, -1) rotate(90.000000) translate(-23.181818, -9.333333) " 
            points="19.6278409 8.5 24.3920455 3.84166667 23.1818182 2.66666667 16.3636364 9.33333333 23.1818182 16 24.3835227 14.825 19.6278409 10.1666667 30 10.1666667 30 8.5"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconFlip'
  }
</script>
