<template>
  <button
    :disabled="working"
    class="nbl-flex nbl-font-button nbl-outline-none nbl-font-normal nbl-items-center nbl-px-4 nbl-py-[6px] nbl-rounded-full nbl-cursor-pointer"
    :class="buttonClass"
    @click="click"
  >
    <div class="nbl-flex">
      <span class="nbl-my-auto nbl-whitespace-no-wrap">
        <slot />
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'NButton',
  props: {
    working: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    },
    softDisabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    buttonClass () {
      return {
        'nbl-bg-blue-norway hover:nbl-bg-blue-hover nbl-text-white': !this.inverted,
        'nbl-border-2 hover:nbl-border-blue-hover hover:nbl-text-blue-hover hover:nbl-bg-grey-300 nbl-border-blue-norway nbl-text-blue-norway': this.inverted,
        'nbl-bg-color-button-disabled nbl-cursor-not-allowed hover:nbl-bg-color-button-disabled': (this.working || this.softDisabled),
      }
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped="true">
</style>