<template>
  <div id="nbl-fares-picker">
    <button 
      :class="pickerClasses" 
      class="nbl-picker nbl-text-nw-base"
      @click.prevent="open = !open" 
    >
      {{ fareSelectionSummary }}
    </button>
    <div 
      v-if="open" 
      class="nbl-mr-5 nbl-z-10 nbl-grid nbl-absolute nbl-border-2 nbl-max-w-md nbl-bg-white nbl-border-yellow-500 nbl-p-5 nbl-mt-2 nbl-grid-cols-1"
    >
      <button 
        aria-label="close" 
        class="nbl-cursor-default nbl-fixed nbl-top-0 nbl-bottom-0 nbl-left-0 nbl-right-0 nbl-w-full nbl-h-full" 
        @click.prevent="close()"
      />
      <div 
        v-for="fare in fares" 
        :key="fare.id"
        class="nbl-grid nbl-z-50 nbl-grid-cols-12" 
      >
        <div 
          :class="fare.count > 0 ? 'nbl-font-semibold' : 'nbl-font-normal'" 
          class="nbl-col-span-8 nbl-text-nw-base nbl-text-blue-norway"
        >
          {{ fare.name }}
        </div>
        <div 
          class="nbl-col-span-4 nbl-flex nbl-flex-row-reverse"
        >
          <button
            aria-label="add"
            class="nbl-bg-add nbl-w-7 nbl-h-7 nbl-bg-no-repeat disabled:nbl-opacity-40"
            @click.prevent="add(fare)"
          />
          <div class="nbl-px-5 nbl-text-nw-base">
            {{ fare.count }}
          </div>
          <button 
            aria-label="remove" 
            :disabled="fare.count == 0" 
            class="nbl-bg-remove nbl-w-7 nbl-h-7 nbl-bg-no-repeat disabled:nbl-opacity-30"
            @click.prevent="remove(fare)" 
          />
        </div>
        <div class="nbl-col-span-12 nbl-mt-1 nbl-mb-2 nbl-text-nw-sm">
          {{ fare.helpText }}
        </div>
      </div>
      <a 
        title="close" 
        class="nbl-underline nbl-text-center nbl-mt-2" 
        href="#"
        @click.prevent="close()" 
      >
        {{ text('Close') }}
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import filter from 'lodash/filter'
  import sumBy from 'lodash/sumBy'
  export default {
    name: 'FarePicker',
    props: {
      position: undefined,
      valid: {
        type: Boolean,
        default: true
      },
    },
    data () {
      return {
        open: false
      }
    },
    computed: {
      ...mapGetters([
        'text', 'fares'
      ]),
      pickerClasses () {
        let classes = [ this.position]
        if (this.open) {
          classes.push('open')
        }
        if (this.numberOfSelectedFares === 0) {
          classes.push('placeholder')
        }
        if (!this.valid) {
          classes.push('invalid')
        }
        return classes.join(' ')
      },
      numberOfSelectedFares () {
        var fares = filter(this.fares, o => o.count > 0)
        return fares.length
      },
      fareSelectionSummary () {
        var fares = filter(this.fares, o => o.count > 0)
        if (fares.length > 1) {
          let numberOfFares = sumBy(fares, 'count')
          return `${numberOfFares} ${this.text('travellers')}` 
        } else if (fares.length === 1) {
          return fares[0].count + ' ' + fares[0].name
        } else {
          return this.text('Select passengers')
        }
      }
    },
    methods: {
      add (fare) {
        this.$store.commit('increaseFare', fare)
      },
      remove (fare) {
        this.$store.commit('decreaseFare', fare)
      },
      close () {
        this.$scrollTo("#app")
        this.open = false
      }
    } 
  }
</script>

<style scoped="true">
  .nbl-picker {
    @apply 
    nbl-border-blue-norway 
    nbl-w-full 
    nbl-text-left 
    nbl-p-2 
    nbl-pr-3
    nbl-pl-12
    nbl-h-12 
    nbl-outline-none
    nbl-text-color-input-active
    nbl-bg-person
    nbl-bg-no-repeat
    nbl-bg-left
    nbl-border
    nbl-text-nw-base;
    background-position-x: 14px;
  }

  .nbl-picker.invalid {
    @apply nbl-border-red-500 nbl-border-2
  }

  .nbl-picker:focus {
    @apply nbl-border-2 nbl-border-yellow-500 !important
  }

  .nbl-picker.placeholder {
    @apply nbl-text-color-input-placeholder;
  }

  .nbl-picker.open {
    @apply nbl-border-2 nbl-border-yellow-500
  }

</style>