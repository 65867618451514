<template>
  <div>
    <h2 class="nbl-text-blue-norway nbl-font-normal nbl-leading-none nbl-text-nw-2xl">
      {{ text('Heading') }}
    </h2>
    <div class="nbl-relative nbl-flex nbl-flex-wrap nbl-mt-8 md:nbl-flex-nowrap nbl-items-center">
      <div class="nbl-w-full nbl-text-nw-base md:nbl-w-6/12">
        <stop-search
          :valid="!origin && showWarning ? false : true"
          component-id="departureSearch"
          component-prefix="departure_"
          :current-location-name="get(origin, 'name')"
          :label="text('Travel from')"
          @selectStop="setOrigin"
        />
      </div>
      <div 
        style="z-index: 5;" 
        class="nbl-absolute md:nbl-static nbl-top-50px md:nbl-top-4 nbl-right-3 md:nbl-right-auto md:nbl-ml-5"
      >
        <button 
          slot="flip-travel" 
          :aria-label="text('flip direction')" 
          class="nbl-bg-blue-norway nbl-block nbl-hover:bg-blue-hover nbl-focus:outline nbl-outline-yellow-500 nbl-w-9 nbl-h-9 nbl-p-1"
          @click.prevent="flipTravel" 
        >
          <icon-flip
            class="nbl-h-7 nbl-w-7 nbl-transform md:nbl-rotate-90" 
            :alt="text('flip direction')" 
          />
        </button>
      </div>
      <div class="nbl-w-full md:nbl-w-6/12 md:nbl-ml-4 nbl-mt-4 md:nbl-mt-0">
        <stop-search
          :valid="!destination && showWarning ? false : true"
          :classes="destination !== undefined ? 'valid' : 'invalid'"
          component-id="arrivalSearch"
          component-prefix="arrival"
          :current-location-name="get(destination, 'name')"
          :label="text('Travel to')"
          @selectStop="setDestination"
        />
      </div>
    </div>

    <div class="nbl-mt-5 nbl-grid nbl-gap-2 nbl-grid-cols-2">
      <div>
        <date-picker
          v-model="departureDate" 
          :valid="!departureDate && showWarning ? false : true" 
          position="left" 
          :icon="true" 
          :placeholder="text('Departure')"
          :clearable="false"
          :options="departureOptions"
        />
      </div>
      <div class="nbl-flex nbl-justify-end">
        <n-button
          :soft-disabled="!origin || !destination" 
          @click="search"
        >
          {{ text('Next') }}  
        </n-button>
      </div>
    </div>
  </div>
</template>

<script>
import IconFlip from '@/components/IconFlip.vue'
import StopSearch from '@/components/StopSearch.vue'
import DatePicker from '@/components/DatePicker.vue'
import { mapGetters } from 'vuex'
import { Norwegian } from 'flatpickr/dist/l10n/no'
import { english } from 'flatpickr/dist/l10n/default'
import get from 'lodash/get'
import NButton from '@/components/NButton.vue'

export default {
  name: 'CampaignMode',
  components: {
    IconFlip,
    StopSearch,
    DatePicker,
    NButton
  },
  data() {
    return {
      origin: undefined,
      destination: undefined,
      departureDate: undefined,
      
      showWarning: false
    }
  },
  computed: {
    ...mapGetters([
      'text', 'fares', 'dateOnServer'
    ]),

    canSubmit () {
      return this.origin && this.destination && this.departureDate
    },

    redirectParameters() {
      let query = {
        originId: this.origin ? this.origin.id : undefined,
        originName: this.origin ? this.origin.name : undefined,
        destinationId: this.destination ? this.destination.id : undefined,
        destinationName: this.destination ? this.destination.name : undefined,
        departureDate: this.departureDate,
      }

      return '?' + new URLSearchParams(query).toString();
    },

    redirectUrl() {
      return this.lang === 'en' ? window.__NWREDIRECT_EN : window.__NWREDIRECT_NO
    },
    returnOptions() {
      return {
        altFormat: 'd. M',
        dateFormat: 'Y-m-d',
        disableMobile: true,
        altInput: true,
        locale: this.lang === 'en' ? english : Norwegian,
        minDate: this.dateOnServer
      }
    },
    departureOptions() {
      return {
        altFormat: 'd. F',
        dateFormat: 'Y-m-d',
        disableMobile: true,
        altInput: true,
        locale: this.lang === 'en' ? english : Norwegian,
        minDate: this.$store.getters.dateOnServer
      };
    }
  },
  created () {
    this.departureDate = this.dateOnServer
  },
  methods: {
    get,
    search() {
      if (this.canSubmit) {
        this.$nextTick(() => {
          window.location.href = this.redirectUrl + '/#/utreise' + this.redirectParameters
        })
      } else {
        this.showWarning = true
      }
    },
    setDestination(location) {
      this.destination = location
    },
    setOrigin(location) {
      this.origin = location
    },
    flipTravel() {
      let temp = this.origin
      this.origin = this.destination
      this.destination = temp
    }
  },
}
</script>
