import { render, staticRenderFns } from "./NButton.vue?vue&type=template&id=018d0853&scoped=true&"
import script from "./NButton.vue?vue&type=script&lang=js&"
export * from "./NButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018d0853",
  null
  
)

export default component.exports