// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/icon-plus.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "input[data-v-7eed3684]{height:3rem;width:100%;border-width:1px;--tw-border-opacity:1;border-color:rgb(42 52 122/var(--tw-border-opacity));padding-right:.75rem;padding-left:3rem;font-size:1.0625rem;line-height:1;--tw-text-opacity:1;color:rgb(42 52 122/var(--tw-text-opacity));outline:2px solid transparent;outline-offset:2px}input.show-icon[data-v-7eed3684]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:0;background-repeat:no-repeat;font-size:1.0625rem;background-position-x:16px}input.hide-icon[data-v-7eed3684]{padding-left:.75rem}input[data-v-7eed3684]::-moz-placeholder{--tw-text-opacity:1;color:rgb(89 89 89/var(--tw-text-opacity))}input[data-v-7eed3684]::placeholder{--tw-text-opacity:1;color:rgb(89 89 89/var(--tw-text-opacity))}input[data-v-7eed3684]:focus{border-width:2px;--tw-border-opacity:1;border-color:rgb(255 223 90/var(--tw-border-opacity))}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
