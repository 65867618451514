// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/icon-person.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nbl-picker[data-v-3b415f68]{height:3rem;width:100%;border-width:1px;--tw-border-opacity:1;border-color:rgb(42 52 122/var(--tw-border-opacity));background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:0;background-repeat:no-repeat;padding:.5rem;padding-right:.75rem;padding-left:3rem;text-align:left;font-size:1.0625rem;--tw-text-opacity:1;color:rgb(42 52 122/var(--tw-text-opacity));outline:2px solid transparent;outline-offset:2px;background-position-x:14px}.nbl-picker.invalid[data-v-3b415f68]{border-width:2px;--tw-border-opacity:1;border-color:rgb(239 68 68/var(--tw-border-opacity))}.nbl-picker[data-v-3b415f68]:focus{border-width:2px!important;--tw-border-opacity:1!important;border-color:rgb(255 223 90/var(--tw-border-opacity))!important}.nbl-picker.placeholder[data-v-3b415f68]{--tw-text-opacity:1;color:rgb(89 89 89/var(--tw-text-opacity))}.nbl-picker.open[data-v-3b415f68]{border-width:2px;--tw-border-opacity:1;border-color:rgb(255 223 90/var(--tw-border-opacity))}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
