<template>
  <div class="">
    <div class="nbl-w-full nbl-text-nw-base nbl-relative">
      <input 
        v-model="content" 
        :class="classes" 
        type="text" 
        :placeholder="text('Discount')"
      >
      <a 
        v-if="content.length > 0"
        title="clear" 
        class="nbl-absolute nbl-right-3 nbl-top-3 nbl-z-10 nbl-cursor-pointer" 
        @click.prevent="content = ''" 
      >
        <icon-remove class="nbl-h-6 nbl-w-6" />
      </a>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import IconRemove from '@/components/IconRemove.vue'
  export default {
    name: 'DiscountCode',
    components: {
      IconRemove
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      position: {
        type: String,
        default: 'left'
      }
    },
    
    data () {
      return {
        content: this.value,
      }
    },
    computed: {
      ...mapGetters([
        'text'
      ]),
      classes () {
        const classes = [this.content.length == 0 ? 'show-icon' : 'hide-icon'] 
        classes.push(this.position === 'left' ? 'left' : 'right')
        classes.push(this.content.length === 0 ? 'show-icon' : 'hide-icon')
        return classes.join(' ')
      }
    },
    watch: {
      content (val) {
        this.$emit('input', val)
      }
    }
  }
</script>

<style scoped>
  input {
    @apply
    nbl-border
    nbl-border-blue-norway 
    nbl-leading-none 
    nbl-w-full 
    nbl-h-12 
    nbl-pr-3
    nbl-pl-12
    nbl-outline-none
    nbl-text-color-input-active 
    nbl-text-nw-base
  }

  input.show-icon {
    @apply 
    nbl-bg-plus
    nbl-bg-no-repeat
    nbl-bg-left
    nbl-text-nw-base;
    background-position-x: 16px;
  }

  input.hide-icon {
    @apply
    nbl-pl-3
  }

  input::placeholder {
    @apply nbl-text-color-input-placeholder
  }

  input:focus {
    @apply nbl-border-2 nbl-border-yellow-500
  }
</style>