// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/icon-search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#autosuggest__input{height:3.75rem;width:100%;border-width:1px;--tw-border-opacity:1;border-color:rgb(42 52 122/var(--tw-border-opacity));background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:0;background-repeat:no-repeat;padding-right:2rem;padding-left:3rem;font-size:1.0625rem;font-weight:400;--tw-text-opacity:1;color:rgb(42 52 122/var(--tw-text-opacity));background-position-x:16px}#autosuggest__input:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-x:50px}.invalid #autosuggest__input{border-width:2px;--tw-border-opacity:1;border-color:rgb(239 68 68/var(--tw-border-opacity))}#autosuggest__input:focus{border-width:2px;--tw-border-opacity:1;border-color:rgb(255 223 90/var(--tw-border-opacity));outline:2px solid transparent;outline-offset:2px}#autosuggest__input::-moz-placeholder{--tw-text-opacity:1;color:rgb(89 89 89/var(--tw-text-opacity))}#autosuggest__input::placeholder{--tw-text-opacity:1;color:rgb(89 89 89/var(--tw-text-opacity))}.autosuggest__results{position:absolute;z-index:20;width:100%;border-right-width:2px;border-left-width:2px;--tw-border-opacity:1;border-color:rgb(255 223 90/var(--tw-border-opacity));line-height:1}.autosuggest__results-item{cursor:pointer;border-bottom-width:1px;--tw-border-opacity:1;border-color:rgb(0 0 0/var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgb(255 255 255/var(--tw-bg-opacity));padding:.75rem;padding-left:1.25rem;font-size:1.0625rem;line-height:1.75rem;--tw-text-opacity:1;color:rgb(42 52 122/var(--tw-text-opacity))}.autosuggest__results-item:last-child{border-bottom-width:2px;--tw-border-opacity:1;border-color:rgb(255 223 90/var(--tw-border-opacity))}.autosuggest__results-item--highlighted,.autosuggest__results-item:hover{background-color:#d9e7f8}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
