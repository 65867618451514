import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

const api = {
  async StopSearch(name) {
    let params = {
      name_filter: name,
      page_size: 20,
    }

    let response = await axios.get('stops', { params })
    return response.data
  }
}

export default api