<template>
  <svg
    width="18" 
    height="18" 
    viewBox="-3 -3 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <line 
      x1="4.75734"
      y1="4.75737"
      x2="13.2426" 
      y2="13.2427" 
      stroke="#595959" 
      stroke-width="2" 
    />
    <line 
      x1="4.75737" 
      y1="13.2426" 
      x2="13.2427" 
      y2="4.75735" 
      stroke="#595959" 
      stroke-width="2" 
    />
  </svg>
</template>

<script>
export default {
  name: 'IconRemove'
}
</script>