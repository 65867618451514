<template>
  <div 
    id="app" 
    class="nbl-p-5"
  >
    <campaign-mode v-if="isCampaign" />
    <full-mode v-else />
  </div>
</template>

<script>

import get from 'lodash/get';
import FullMode from './pages/FullMode.vue';
import CampaignMode from './pages/CampaignMode.vue';

export default {
  name: 'TravelPlanner',
  components: {
    FullMode,
    CampaignMode
  },
  computed: {
    isCampaign () {
      return get (window, '__NWISCAMPAIGN', false)
    }
  },
  mounted () {
    this.$store.commit('setLanguage', window.__NWLANG || 'nb-NO')
  }
}
</script>

<style>
#app {
  margin-top: 60px;
}

/* Safari reset rounded corners */

</style>
