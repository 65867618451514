export const texts = Object.freeze({
  heading: {
    nb: 'Hvor vil du reise?',
    en: 'Plan your journey'
  },
  abort: {
    nb: 'Avbryt',
    en: 'Cancel'
  },
  close: {
    nb: 'Lukk',
    en: 'Close'
  },
  date: {
    nb: 'dato',
    en: 'date'
  },
  discount: {
    nb: 'rabattkode',
    en: 'discount code'
  },
  next : {
    nb: 'Vis tid og pris',
    en: 'Show time and price'
  },
  warning: {
    nb: 'Velg både fra og til',
    en: 'Please select both from and to'
  },
  departure: {
    nb: 'Velg reisedato',
    en: 'Select departure date'
  },
  'enter location': {
    nb: 'skriv inn sted',
    en: 'enter location'
  },
  'return': {
    nb: 'Retur',
    en: 'Return'
  },
  'return date': {
    nb: 'Velg returdato',
    en: 'Select return date'
  },
  'ticket type': {
    nb: 'billettype',
    en: 'ticket type'
  },
  'travel from': {  
    nb: 'jeg vil reise fra holdeplass',
    en: 'I will travel from'
  },
  'travel to': {
    nb: 'jeg vil reise til holdeplass',
    en: 'I will travel to'
  },
  'travellers': {
    nb: 'reisende',
    en: 'passengers'
  },
  'return trip': {  
    nb: 'returreise',
    en: 'return trip'
  },
  'select passengers': {
    nb: 'velg reisende',
    en: 'select passengers'
  },
  // -- FARES -- //
  'fare-3d2fe8d3-c3c9-44b8-917c-725d859376ba-name': {
    nb: 'Voksen',
    en: 'Adult'
  },
  'fare-3d2fe8d3-c3c9-44b8-917c-725d859376ba-text': {
    nb: 'Fra 18 år',
    en: 'From 18 years'
  },
  'fare-b942bbca-99df-446f-99e8-ee8191066fa6-name': {
    nb: 'Barn',
    en: 'Child'
  },
  'fare-b942bbca-99df-446f-99e8-ee8191066fa6-text': {
    nb: '6 - 17 år',
    en: '6 - 17 years'
  },
  'fare-21650b77-1a9b-48eb-972f-d5ea43a78faf-name': {
    nb: 'Småbarn',
    en: 'Child'
  },
  'fare-21650b77-1a9b-48eb-972f-d5ea43a78faf-text': {
    nb: '0 - 5 år',
    en: '0 - 5 years'
  },
  'fare-83d3e907-afd2-4e26-9d73-c812efa9fc83-name': {
    nb: 'Student',
    en: 'Student'
  },
  'fare-83d3e907-afd2-4e26-9d73-c812efa9fc83-text': {
    nb: 'Elev eller student, 18 - 30 år med gyldig skolebevis/studentbevis.',
    en: 'Student, 18 - 30 years old with a valid student card.'
  },
  'fare-89c95211-ea6a-49d8-a46d-2513b82e8e39-name': {
    nb: 'Honnør',
    en: 'Senior'
  },
  'fare-89c95211-ea6a-49d8-a46d-2513b82e8e39-text': {
    nb: 'Fra fylte 67 år og personer med uførebevis fra NAV.',
    en: 'From 67 years and holders of NAV disability certificate.'
  },
  'fare-b06ed0cd-5b19-4d43-91d1-e7b318d1cb5e-name': {
    nb: 'Vernepliktig',
    en: 'Military'
  },
  'fare-b06ed0cd-5b19-4d43-91d1-e7b318d1cb5e-text': {
    nb: 'Avtjener førstegangstjeneste og kan fremvise forsvarets ID-kort for vernepliktige eller innkallingsbrev til førstegangstjeneste.',
    en: 'In compulsory military service with valid ID-card from the Defence ministry.'
  },
  'fare-2beab689-2023-421a-8112-6efbca15e961-name': {
    nb: 'Ledsager',
    en: 'Carer'
  },
  'fare-2beab689-2023-421a-8112-6efbca15e961-text': {
    nb: 'Person som ledsager en innehaver av kommunalt ledsagerbevis.',
    en: 'Person who accompanies a holder of carer certificate issued by the local municipality.'
  }
})