<template>
  <div 
    class="nbl-datepicker nbl-relative" 
    :class="classes"
  >
    <flat-pickr 
      v-model="date"
      :placeholder="placeholder" 
      class="nbl-w-full nbl-p-2 nbl-h-12" 
      aria-label="select traveldate" 
      :tabindex="tabIndex" 
      :config="options"
    />
    <a 
      v-if="clearable && date != null"
      title="Fjern retur" 
      class="nbl-absolute nbl-right-3 nbl-top-3 nbl-z-10 nbl-cursor-pointer" 
      @click.prevent="clearInput" 
    >
      <icon-remove class="nbl-h-6 nbl-w-6" />
    </a>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import { Norwegian } from 'flatpickr/dist/l10n/no.js'
import IconRemove from '@/components/IconRemove.vue'

export default {
  name: 'DatePicker',
  components: {
    flatPickr,
    IconRemove
  },
  props: {
    options: {
      type: Object,
      default () {}
    },
    name: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    val: {
      type: String,
      default: undefined
    },
    value: undefined,
    tabIndex: undefined,
    icon: {
      type: Boolean,
      default: false
    },
    dualIcon: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      startDate: this.value,
      date: this.value
    }
  },
  computed: {
    isWrap () {
      if (this.options) {
        return !!this.options.wrap
      }
      return false
    },
    classes () {
      const res = []
      res.push(this.date ? 'not-empty' : 'empty')
      if (this.icon) {
        res.push('calendar-icon')
      }
      if (this.dualIcon) {
        if (this.date) {
          res.push('calendar-icon')
        } else {
          res.push('plus-icon')
        }
        
      }
      if (!this.valid) {
        res.push('invalid')
      }
      return res.join(' ')
    }
  },
  watch: {
    date (val) {
      this.date = val
      this.$emit('input', this.date)
    },
    startDate () {
      if (this.startDate > this.date) {
        this.date = this.startDate
        this.$emit('input', this.date)
      }
    },
    options (newOptions) {
      // Supports dynamically updating the min date of this datepicker
      // this.flatPickr.set('minDate', newOptions.minDate)
      if (this.startDate < newOptions.minDate) {
        this.startDate = newOptions.minDate
        // this.flatPickr.setDate(newOptions.minDate)
      }
    }
  },
  created () {
    Norwegian.months.shorthand.forEach((month, index) => {
      Norwegian.months.shorthand[index] = month.charAt(0).toLowerCase() + month.slice(1)
    })
  },
  methods: {
    changeVal () {
      this.$emit('input', this.startDate)
    },
    handleClear () {
      this.flatPickr && this.flatPickr.clear()
    },
    clearInput () {
      this.date = null
      this.$emit('input', undefined)
    }
  },
   
}
</script>

<style>
@import '~flatpickr/dist/themes/airbnb.css';

.nbl-datepicker input {
  @apply
  nbl-border-blue-norway 
  nbl-text-nw-base
  nbl-w-full 
  nbl-bg-white 
  nbl-text-left
  nbl-px-3
  nbl-border
  nbl-text-color-input-active;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.invalid input {
  @apply nbl-border-red-500 nbl-border-2
}

.nbl-datepicker.calendar-icon input {
  @apply
  nbl-bg-calendar
  nbl-bg-no-repeat
  nbl-bg-left
  nbl-pl-12;
  background-position-x: 14px !important;

}

.nbl-datepicker.plus-icon input {
  @apply
  nbl-bg-plus
  nbl-bg-no-repeat
  nbl-bg-left
  nbl-pl-12;
  background-position-x: 16px;
}


.nbl-datepicker input::-webkit-date-and-time-value {
  text-align: left;
}

.nbl-datepicker.empty input[type='date']::after { 
  content: attr(placeholder);
  position: absolute;
  width: 100%;
}

.nbl-datepicker input::placeholder {
  @apply nbl-text-color-input-placeholder nbl-pl-0
}

.nbl-datepicker input:focus {
  @apply nbl-border-2 nbl-border-yellow-500 nbl-outline-none;
}

.flatpickr-current-month {
  @apply nbl-pt-1;
}

.flatpickr-calendar {
  font-family: 'Ginto', sans-serif;
  border: 2px solid #e6e6e6; /* Needed to override the default theme border */
  @apply nbl-block nbl-mt-1 nbl-border-yellow-500 !important;
  @apply nbl-border-2 !important;
}

.flatpickr-calendar::before {
  @apply nbl-border-2 !important;
}

.flatpickr-calendar::after {
  @apply nbl-border-2 !important;
}

.flatpickr-prev-month, .flatpickr-next-month {
  @apply nbl-mt-1 nbl-text-black nbl-fill-black
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
   @apply nbl-fill-blue-norway !important
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  @apply nbl-bg-white;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: #e9e9e9;
}

.numInputWrapper:hover {
  background-color: #e9e9e9;
}

span.flatpickr-day.today {
  @apply nbl-border-b-2 nbl-border-b-blue-norway !important
}

span.flatpickr-day.selected {
  @apply nbl-bg-blue-norway nbl-text-white;
}

span.flatpickr-day.selected:hover {
  @apply nbl-bg-blue-norway nbl-text-white nbl-opacity-80;
}

span.flatpickr-day.disabled {
  background: #e9e9e9;
}
</style>
